<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<!-- <el-upload
				  class="upload-demo d-inline-block"
				  ref="upload"
				  action="#"
				  :on-change="importExcel"
				  :on-success="importSuccess"
				  :on-error="importError"
				  :before-upload="beforeImportUpload"
				  :limit="1"
				  :multiple="false"
				  :show-file-list="false"
				  accept=".xls, .xlsx"
				  :auto-upload="false"
				  >
				  <el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入</el-button>
				</el-upload>
				<el-button type="primary" icon="el-icon-download" size="mini" @click="downloadTemplate">导入模板</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="封面" min-width="80" align="center">
				  <template slot-scope="scope">
					<img :src="scope.row.url" style="width: 30px;height: 30px;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
					<img src="@/assets/images/empty.png" style="width: 30px;height: 30px;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="标题" min-width="150" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.title }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.name }}
				  </template>
				</el-table-column>
				<el-table-column label="推荐" min-width="60" align="center">
					<template slot-scope="scope">
						<el-switch
						 v-model="scope.row.is_rec"
						 @change="changeStatus(scope.row,'is_rec')"
						 active-color="#13ce66"
						 :active-value="1"
						 :inactive-value="2">
						 </el-switch>
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="50" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">未发布</el-tag>
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">已发布</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="130">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="1000px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-form-item label="标题" prop="title">
			  <el-input v-model="form.title" placeholder="请输入标题" type="text" clearable />
			</el-form-item>
			<el-form-item label="作者" prop="anthor">
			  <el-input v-model="form.anthor" placeholder="请输入作者" type="text" clearable />
			</el-form-item>
			<el-form-item label="文章类型" prop="type_id">
				 <el-select v-model="form.type_id" placeholder="请选择文章类型" size="medium" class="w-100">
				   <!-- <el-option label="顶级分类" :value="0"></el-option> -->
				   <el-option v-for="(item,index) in types" :key="index"
					 :label="item | tree"
					 :value="item.id">
				   </el-option>
				 </el-select>
			  </el-form-item>
			<el-form-item label="封面" prop="cover">
				<div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
				  @click="chooseImage"
				  >
					<img :src="form.url" class="w-100 h-100" v-if="form.url">
					<i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
				</div>
			</el-form-item>
			<el-form-item label="内容">
			   <tinymce ref="editor" :key="tinymceFlag" v-model="form.content"/>
			</el-form-item>
			<el-form-item  label="发布状态">
			   <el-radio-group v-model="form.status">
			   	<el-radio :label="1">已发布</el-radio>
			   	<el-radio :label="2">未发布</el-radio>
			   </el-radio-group>
			 </el-form-item>
			<el-form-item label="排序" prop="sort">
			  <el-input v-model="form.sort" placeholder="请输入排序" type="text" clearable />
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import tinymce from "@/components/common/tinymce.vue"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
			tinymce
		},
		data() {
			return {
				preUrl: 'article',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					title:'',
					anthor:'admin',
					type_id:'',
					cover:'',
					url:'',
					content:'',
					sort:100,
					is_pub:1,
				},
				rules:{
					title: [
					  { required: true, message: '请输入文章标题', trigger: 'blur' }
					],
					type_id: [
					  { required: true, message: '请选择文章类型', trigger: 'blur' }
					],
				},
				tinymceFlag:0,
				types:[],
			}
		},
		mounted() {
			// this.getArticleTypes()
		},
		methods:{
			getArticleTypes(){
				this.axios.get(`/manage/article_type/getTree`, {
				  params: this.queryForm
				}).then(res=>{
				  if(res.status){
					this.types = res.data
				  }
				})
			},
			handleAdd(){
				this.$router.push({
				  path:"/article_edit",
				  query: {}
				})
            },
			handleEdit(row) {
				// this.reset()
				// this.open = true
				// this.title = '编辑'
				// this.tinymceFlag++;
				// this.form = JSON.parse(JSON.stringify(row))
				this.$router.push({
				  path:"/article_edit",
				  query: {
					  article:JSON.parse(JSON.stringify(row))
				  }
				})
			},
			reset() {
			  this.form = {
			   title:'',
			   anthor:'admin',
			   type_id:'',
			   cover:'',
			   url:'',
			   content:'',
			   sort:100,
			   is_pub:1,
			  }
			  this.resetForm('form')
			},
			chooseImage(){
				this.app.chooseImage((res)=>{
				  this.form.cover = res[0].id
				  this.form.url = res[0].url
				})
			},
			downloadTemplate(){
				this.axios.get('/manage/'+this.preUrl+'/downloadTemplate',{
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '导入模板.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				this.axios.post('/manage/'+this.preUrl+'/importExport',formdata).then(res=>{
					if(res.status){
						this.$message.success('导入成功')
						this.getList()
					}else{
						this.$message.success('导入失败')
					}
					this.$refs.upload.clearFiles()
				})
			},
			beforeImportUpload(file){
				  let fileArr = file.name.split('.')
				  let suffix = fileArr[fileArr.length - 1]
				  //只能导入.xls和.xlsx文件
				  if (!/(xls|xlsx)/i.test(suffix)) {
					this.$message('文件格式不正确')
					return false
				  }
				  return true
			},
	    },
		filters: {
		    tree(item) {
		        if (item.level == 0) {
		          return item.name
		        }
		        let str = ''
		        for (let i = 0; i < item.level; i++) {
		          str += i == 0 ? '|--' : '--'
		        }
		        return str + ' ' +item.name;
		    }
		},
	}
</script>

<style>
</style>